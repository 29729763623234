export type TMetricsEvent = {
  category: EMetricCategory;
  action: EMetricAction;
  label: string;
  dimension1?: string;
  dimension2?: string;
};

export enum EMetricCategory {
  NAVIGATION = 'NAVIGATION',
  SLIDER_NAVIGATTION = 'SLIDER_NAVIGATTION',
  SCHEDULE_DEMO = 'SCHEDULE_DEMO',
  CONTACT_US = 'CONTACT_US',
  CAREERS = 'CAREERS',
}

export enum EMetricAction {
  PAGE_LOAD = 'PAGE_LOAD',
  CLICK = 'CLICK',
  SUBMIT = 'SUBMIT',
}
