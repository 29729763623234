import NeatleafLogo from 'assets/svgs/neatleaf-logo.svg?react';
import NeatleafSmallLogo from 'assets/svgs/neatleaf-small-logo.svg?react';
import { Link } from 'common';
import { HamburgerMenu } from 'components/Header';
import { useScreenSize } from 'hooks/useScreenSize';
import { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { AnalyticsEvent } from 'shared/analytics/AnalyticsEvent';
import { GoogleAnalyticsGA4 } from 'shared/analytics/GoogleAnalyticsGA4';
import { EMetricAction, EMetricCategory } from 'shared/analytics/contracts';
import { useAnalytics } from 'shared/analytics/useAnalytics';
import { ROUTES } from 'shared/constants';
import styled from 'styled-components';

export enum HeaderColor {
  WHITE = 'white',
  BLACK = 'black',
}

interface HeaderProps {
  color: HeaderColor;
  hasPadding?: boolean;
}

export const Header: FC<HeaderProps> = ({ color, hasPadding = true }) => {
  const { sendEvent } = useAnalytics();
  const { isMobile } = useScreenSize();
  const location = useLocation();
  const handleOnClick = () => {
    sendEvent(AnalyticsEvent.DashboardLogin);
    GoogleAnalyticsGA4.sendEvent({
      category: EMetricCategory.NAVIGATION,
      action: EMetricAction.CLICK,
      label: 'Dashboard login',
    });
  };
  return (
    <Container $hasPadding={hasPadding}>
      <Section>
        <RouterLink to={ROUTES.LANDING}>
          {isMobile ? (
            <NeatleafSmallLogo color={color} />
          ) : (
            <NeatleafLogo color={color} />
          )}
        </RouterLink>
        {isMobile ? (
          <HamburgerMenu color={color} current={location.pathname} />
        ) : null}
      </Section>

      <Section>
        <Link
          color="white"
          text="Schedule Demo"
          to={ROUTES.SCHEDULE_DEMO}
          variant="contained"
          onClick={() => sendEvent(AnalyticsEvent.RequestDemoStarted)}
        />

        {!isMobile ? (
          <LinkGroup $hasBorder={color === HeaderColor.WHITE}>
            <Link color={color} text="About Us" to={ROUTES.ABOUT_US} />
            <Link color={color} text="Careers" to={ROUTES.CAREERS} />
            <Link color={color} text="Contact Us" to={ROUTES.CONTACT_US} />
            <Link color={color} text="Blog" to={ROUTES.BLOG} />
            <Link color={color} text="Newsroom" to={ROUTES.NEWSROOM} />
            <Link
              color={color}
              text="Login"
              target="_blank"
              to={ROUTES.LOGIN}
              onClick={handleOnClick}
            />
          </LinkGroup>
        ) : null}
      </Section>
    </Container>
  );
};

interface ContainerProps {
  $hasPadding: boolean;
}

const Container = styled.header<ContainerProps>(({ $hasPadding }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: $hasPadding ? '20px' : 0,
}));

const Section = styled.div({
  display: 'flex',
  alignItems: 'stretch',
  gap: 8,
});

interface LinkGroupProps {
  $hasBorder: boolean;
}

const LinkGroup = styled.div<LinkGroupProps>(({ $hasBorder }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '12px 24px',
  borderRadius: 60,
  border: $hasBorder ? '1px solid white' : 'none',
  background: 'rgba(0, 0, 0, 0.05)',
  gap: 24,
}));
