import { Footer } from 'components/Footer/Footer';
import { Header, HeaderColor } from 'components/Header';
import ResponsiveImg, { IResponsiveImg } from 'components/ResponsiveImg';
import { useScrollToTop } from 'hooks';
import { FC, ReactNode } from 'react';
import { COLORS, DEVICES } from 'shared/constants';
import styled, { CSSProperties } from 'styled-components';

const Layout: FC<{
  children: ReactNode;
  backgroundColor?: CSSProperties['backgroundColor'];
}> = ({ children, backgroundColor }) => {
  useScrollToTop();

  return (
    <Container $backgroundColor={backgroundColor}>
      <HeaderContainer>
        <Header color={HeaderColor.BLACK} />
      </HeaderContainer>

      {children}

      <Footer />
    </Container>
  );
};

const Container = styled.div<{
  $backgroundColor?: CSSProperties['backgroundColor'];
}>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content auto max-content;
  min-height: 100%;
  background-color: ${(props) => props.$backgroundColor ?? COLORS.dark10};

  @media ${DEVICES.sm} {
    min-height: 100vh;
  }
`;

const HeaderContainer = styled.div`
  background: rgba(255, 255, 255, 1);
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  align-content: center;
  justify-content: center;
  gap: 60px;
  grid-row-gap: 48px;
  padding: 65px 20px;

  @media ${DEVICES.md} {
    grid-template-columns: 1fr 320px;
    grid-template-rows: max-content;
    grid-row-gap: 0;
    padding: 130px 80px;
  }

  @media ${DEVICES.xl} {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
  }
`;

export const Section = styled.div`
  max-width: 100%;

  @media ${DEVICES.lg} {
    max-width: 620px;
  }
`;

export const Banner = styled((props: IResponsiveImg) => {
  return (
    <ResponsiveImg {...props} maxHeight="500px" useExtendedResolution={true} />
  );
})<IResponsiveImg>({});

export default Layout;
