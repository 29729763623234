import { FC } from 'react';
import styled from 'styled-components';

import { Typography } from 'common';
import Layout, { Content } from 'common/Layout';
import { Posts } from 'components/Blog';
import { COLORS } from 'shared/constants';

export const Blog: FC = () => {
  return (
    <Layout backgroundColor={COLORS.white}>
      <BlogContent>
        <Typography variant="h4" tag="h1" margin="0 0 46px 0">
          Blog
        </Typography>
        <Posts />
      </BlogContent>
    </Layout>
  );
};

const BlogContent = styled(Content)`
  grid-template-columns: 1fr;
  align-content: start;
`;
