import { Button, Typography } from 'common';
import { FC, ReactNode } from 'react';
import { AnalyticsEvent } from 'shared/analytics/AnalyticsEvent';
import { GoogleAnalyticsGA4 } from 'shared/analytics/GoogleAnalyticsGA4';
import { EMetricAction, EMetricCategory } from 'shared/analytics/contracts';
import { useAnalytics } from 'shared/analytics/useAnalytics';
import { DEVICES } from 'shared/constants';
import { TSliderItem } from 'shared/types';
import styled from 'styled-components';

interface IButtonSlidersProps {
  current: number;
  items: TSliderItem[];
  title: ReactNode;
  onChange: (index: number) => void;
}

export const ButtonSliders: FC<IButtonSlidersProps> = ({
  current,
  items,
  title,
  onChange,
}) => {
  const { sendEvent } = useAnalytics();
  const currentItem = items[current];
  const handleOnClick = (index: number, button: string) => {
    sendEvent(AnalyticsEvent.SliderNavigation);
    GoogleAnalyticsGA4.sendEvent({
      category: EMetricCategory.SLIDER_NAVIGATTION,
      action: EMetricAction.CLICK,
      label: button,
    });
    onChange(index);
  };
  return (
    <Sliders>
      <Title variant="h5" tag="h2">
        {title}
      </Title>
      <ButtonGroup>
        {items.map((item, index) => (
          <Button
            key={item.button}
            color={index === current ? 'primary' : 'tertiary'}
            onClick={() => handleOnClick(index, item.button)}
          >
            {item.button}
          </Button>
        ))}
      </ButtonGroup>

      <Description variant="body1" tag="p">
        {currentItem.description}
      </Description>
    </Sliders>
  );
};

const Sliders = styled.div`
  display: grid;
  grid-template-rows: max-content max-content max-content;
  grid-template-columns: 1fr;
  gap: 45px;
  width: 100%;
  height: 100%;
  padding: 45px 30px;

  @media ${DEVICES.sm} {
    grid-template-rows: max-content max-content auto;
    gap: 60px;
    padding: clamp(6%, 12%, 18%);
  }

  @media ${DEVICES.md} {
    grid-template-rows: 15% max-content auto;
  }
  @media ${DEVICES.lg} {
    grid-template-rows: 18% max-content auto;
  }
`;

const Title = styled(Typography)`
  align-self: end;
  max-width: 480px;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  grid-row: 2;

  > * {
    flex-grow: 1;
    flex-basis: calc(50% - 8px); // NOTE: minus the gap

    @media ${DEVICES.md} {
      flex-grow: 0;
      flex-basis: auto;
    }
  }
`;

const Description = styled(Typography)`
  @media ${DEVICES.md} {
    height: 300px;
  }
`;
