/**
 * Helper function to parse a string into an enum
 * Throws an error if the value can't be parsed
 *
 * Usage:
 * enum Foo { Bar = 'bar' }
 * const enumValue = parseEnumValue(Foo, 'bar'); // enumValue now has the type Foo
 *
 */
export function parseEnumValue<T extends string, TEnumValue extends string>(
  enumVariable: { [key in T]: TEnumValue },
  value: string
): TEnumValue {
  const values = getEnumValues(enumVariable);

  if (values.includes(value as TEnumValue)) {
    return value as TEnumValue;
  }
  throw new Error(`Failed to parse ${value} into one of [${values.join(',')}]`);
}

/**
 * Helper function to parse a string array into an enum array
 * Throws an error if any of the values can't be parsed
 *
 * Usage:
 * enum Foo { Bar = 'bar', Baz = 'baz' }
 * const enumValues = parseEnumValue(Foo, ['bar', 'baz']); // enumValues now has the type Foo[]
 *
 */
export function parseEnumArray<T extends string, TEnumValue extends string>(
  enumVariable: { [key in T]: TEnumValue },
  values: string[]
): TEnumValue[] {
  return values.map((v) => parseEnumValue(enumVariable, v));
}

/**
 * Helper function to get all values of an enum
 *
 */
export function getEnumValues<
  T extends string,
  TEnumValue extends string,
>(enumVariable: {
  [key in T]: TEnumValue;
}): TEnumValue[] {
  const enumValues = Object.values(enumVariable);
  return enumValues as TEnumValue[];
}

/**
 * Helper function to find out if a string is an enum value
 */
export function isEnumValue<T extends string, TEnumValue extends string>(
  enumVariable: {
    [key in T]: TEnumValue;
  },
  value: string
): value is TEnumValue {
  return getEnumValues(enumVariable).includes(value as TEnumValue);
}

/**
 * Helper function to get all values of a numeric enum
 */
export function getNumericEnumValues<
  T extends string,
  TEnumValue extends number,
>(enumVariable: {
  [key in T]: TEnumValue;
}): TEnumValue[] {
  const enumValues = Object.values(enumVariable);
  return enumValues as TEnumValue[];
}

/**
 * Helper function to find out if a number is an enum value
 */
export function isNumericEnumValue<T extends string, TEnumValue extends number>(
  enumVariable: {
    [key in T]: TEnumValue;
  },
  value: number
): value is TEnumValue {
  return getNumericEnumValues(enumVariable).includes(value as TEnumValue);
}
