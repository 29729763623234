import {
  AppConfig,
  Environment,
  MixpanelConfig,
  ReleaseConfig,
} from './AppConfig';
import {
  getBoolean,
  getEnumValue,
  getOptionalString,
  getString,
} from './config-utils';

export function createAppConfig(values: Record<string, string>): AppConfig {
  const appConfig: AppConfig = {
    environment: getEnumValue(values, 'ENV', Environment),
    ga4TrackingId: getOptionalString(values, 'GA4_TRACKING_ID'),
    mailSubmitApi: getString(values, 'MAIL_SUBMIT_API'),
    mixpanel: createMixpanelConfig(values),
    release: createReleaseConfig(values, 'fleet-management'),
    s3AssetsUrl: getString(values, 'S3_ASSETS_URL'),
  };

  return appConfig;
}

function createReleaseConfig(
  configValues: Record<string, string>,
  applicationName: string
): ReleaseConfig {
  const branchName = getOptionalString(configValues, 'COMMIT_BRANCH');
  const tagName = getOptionalString(configValues, 'COMMIT_TAG');
  const commitShortSha = getString(configValues, 'COMMIT_SHORT_SHA');
  const commitSha = getString(configValues, 'COMMIT_SHA');
  const tagVersion = tagName && getTagVersion(tagName);
  return {
    branchName,
    commitSha,
    commitShortSha,
    releaseName: getRelease(
      applicationName,
      commitShortSha,
      branchName,
      tagName
    ),
    tagName,
    tagVersion,
  };
}

function getTagVersion(tagName: string): string {
  const match = tagName.match(/[0-9]+\.[0-9]+\.[0-9]+/);
  if (!match?.length) {
    throw new Error(`Could not get tag version from tag name ${tagName}`);
  }
  return match[0];
}

function getRelease(
  applicationName: string,
  commitHash: string,
  branchName: string | undefined,
  tagName: string | undefined
): string {
  if (tagName) {
    return `${applicationName}@${tagName}`;
  }

  // We can use max. 200 characters for a release in sentry
  // and no forward slashes are allowed
  // https://docs.sentry.io/cli/releases/
  const sanitizedBranchName = (branchName || '').replace('/', '__');
  const refName = sanitizedBranchName.substring(
    0,
    200 - (applicationName.length + commitHash.length + 2)
  );

  return `${applicationName}@${refName}-${commitHash}`;
}

function createMixpanelConfig(values: Record<string, string>): MixpanelConfig {
  const apiToken = getString(values, 'MIXPANEL_API_TOKEN');
  const mixpanelConfig: MixpanelConfig = {
    apiToken,
    enabled: getBoolean(values, 'MIXPANEL_ENABLED'),
    debuggingEnabled: getBoolean(values, 'MIXPANEL_DEBUGGING_ENABLED'),
  };
  return mixpanelConfig;
}
