import MaskedLogo from 'assets/svgs/maked-logo.svg?react';
import { Typography } from 'common';
import { useScreenSize } from 'hooks';
import { FC } from 'react';
import { COLORS } from 'shared/constants';
import { IResponsiveLayoutProps } from 'shared/types';
import styled from 'styled-components';

export const IndustryValues: FC = () => {
  const { isMobile } = useScreenSize();

  return (
    <Container $isMobile={isMobile}>
      <PositionedLogo $isMobile={isMobile} />

      <MetaTitle variant="body2" tag="h2">
        AN INDUSTRY FIRST
      </MetaTitle>
      <Explanation variant="h6" tag="p" color="grey6" fontWeight="400">
        <b>
          The Neatleaf Spyder enables you to remotely monitor and optimize your
          growing environment for each individual plant.
        </b>
        &nbsp;Know your environment conditions in every corner and rely on
        automatically collected plant health and growth metrics to guide your
        decision making.
      </Explanation>
    </Container>
  );
};

const Container = styled.section<IResponsiveLayoutProps>(({ $isMobile }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  background: COLORS.orange4,
  width: '100%',
  padding: $isMobile ? '88px 28px 220px' : '108px 76px',
  position: 'relative',
  overflow: 'hidden',
}));

const MetaTitle = styled(Typography)({
  color: COLORS.grey6,
  fontWeight: 700,
});

const Explanation = styled(Typography)({
  maxWidth: 700,
  zIndex: 0,
});

const PositionedLogo = styled(MaskedLogo)<IResponsiveLayoutProps>(
  ({ $isMobile }) => ({
    position: 'absolute',
    bottom: 0,
    right: $isMobile ? -60 : 60,
  })
);
