import Layout, { Banner } from 'common/Layout';
import { useScrollToTop } from 'hooks';
import { FC } from 'react';
import { ScheduleDemo } from './ScheduleDemo';

const scheduleDemoSrc = 'order-now.png';

export const ScheduleDemoPage: FC = () => {
  useScrollToTop();

  return (
    <Layout>
      <Banner filename={scheduleDemoSrc} />
      <ScheduleDemo />
    </Layout>
  );
};
