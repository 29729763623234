import { FC } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Hr, Typography } from 'common';
import Layout, { Content, Section } from 'common/Layout';
import { Markdown, Posts } from 'components/Blog';
import { useAppConfig } from 'contexts/AppConfigProvider';
import { useFetch, useQueryParams } from 'hooks';
import { COLORS, SIZES } from 'shared/constants';
import { getS3AssetsUrl } from 'shared/utils';

interface Author {
  id: string;
  name: string;
  role: string;
  image: string;
  bio: string;
}

export const Post: FC = () => {
  const { appConfig } = useAppConfig();
  const { postId } = useParams<Record<string, string>>();
  const { data: md } = useFetch<string>(
    getS3AssetsUrl(appConfig.s3AssetsUrl, `blog/${postId}.md`)
  );
  const { authorId: authorId } = useQueryParams();
  const { data: author } = useFetch<Author>(
    getS3AssetsUrl(appConfig.s3AssetsUrl, `blog/author-${authorId}.json`)
  );

  return (
    <Layout backgroundColor={COLORS.white}>
      <Content>
        <PostSection>
          {md && (
            <div>
              <Markdown>{md}</Markdown>
            </div>
          )}
          <Hr />
          {author && (
            <Profile>
              <ImgNameRole>
                <Img
                  src={getS3AssetsUrl(appConfig.s3AssetsUrl, author.image)}
                />
                <NameRole>
                  <Typography variant="body1" fontWeight="700">
                    Written by {author.name}
                  </Typography>
                  <Typography variant="body2" color="black50">
                    {author.role}
                  </Typography>
                </NameRole>
              </ImgNameRole>
              <Typography variant="body3">{author.bio}</Typography>
            </Profile>
          )}
        </PostSection>
        <Posts complete={false} />
      </Content>
    </Layout>
  );
};

const PostSection = styled(Section)`
  max-width: max-content;
  display: flex;
  flex-direction: column;
  gap: ${SIZES.small}px;
`;

const Profile = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${SIZES.small}px;
`;

const ImgNameRole = styled.div`
  display: flex;
  gap: ${SIZES.xSmall}px;
  align-items: center;
`;

const NameRole = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZES.xSmall}px;
`;

const Img = styled.img`
  height: 90px;
  width: 90px;
  object-fit: cover;
  border-radius: 50%;
`;
