import { FC } from 'react';
import styled from 'styled-components';

import { Typography } from 'common';
import { useAppConfig } from 'contexts/AppConfigProvider';
import { useFetch } from 'hooks';
import { COLORS, DEVICES, SIZES } from 'shared/constants';
import { getS3AssetsUrl } from 'shared/utils';

interface Partner {
  url: string;
  imageUrl: string;
}

export const Partners: FC = () => {
  const { appConfig } = useAppConfig();
  const { data: partners = [] } = useFetch<Partner[]>(
    getS3AssetsUrl(appConfig.s3AssetsUrl, 'partners/index.json')
  );

  if (partners.length === 0) {
    return null;
  }

  return (
    <Section>
      <Typography variant="h5" tag="h2">
        Our Partners
      </Typography>
      <List>
        {partners.map(({ url, imageUrl }) => {
          return (
            <Link key={url} href={url} target="_blank">
              <Img src={getS3AssetsUrl(appConfig.s3AssetsUrl, imageUrl)} />
            </Link>
          );
        })}
      </List>
    </Section>
  );
};

const Section = styled.section`
  padding: 80px 24px;
  display: flex;
  flex-direction: column;
  gap: ${SIZES.xxLarge}px;
  align-items: center;

  @media ${DEVICES.sm} {
    padding: 80px;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${SIZES.xxLarge}px;
`;

const Link = styled.a`
  display: flex;
  justify-content: center;
  width: 200px;
  border-radius: ${SIZES.medium}px;
  text-decoration: none;
  color: ${COLORS.black};
  &:visited {
    color: ${COLORS.black};
  }
`;

const Img = styled.img`
  height: 50px;
  object-fit: contain;
  max-width: 100%;
  filter: grayscale(100%);
`;
