import { FC } from 'react';
import { Typography } from 'common';
import Layout, { Banner, Content, Section } from 'common/Layout';

import { COLORS } from 'shared/constants';

const aboutUsSrc = 'about-us.jpg';

export const AboutUs: FC = () => {
  return (
    <Layout backgroundColor={COLORS.white}>
      <Banner filename={aboutUsSrc} />

      <Content>
        <Section>
          <Typography variant="h4" tag="h1" margin="0 0 24px 0">
            About Us
          </Typography>
          <Typography variant="h6" margin="0 0 24px 0">
            Founded in 2020, Neatleaf&apos;s mission is to enable cultivators to
            grow their crops in the most efficient and sustainable way.
          </Typography>
          <Typography variant="h6" margin="0 0 24px 0">
            With offices in the San Francisco Bay Area and Munich, Germany, we
            are bringing together a multidisciplinary and diverse group of
            world-class professionals. Our talent comes from some of the
            world&apos;s leading technology companies and research institutions.
          </Typography>
          <Typography variant="h6" margin="0 0 24px 0">
            We are committed to connecting people with plants and data, and
            creating a positive impact for our planet.
          </Typography>
        </Section>
      </Content>
    </Layout>
  );
};
