import { Typography } from 'common';
import { useAppConfig } from 'contexts/AppConfigProvider';
import 'katex/dist/katex.min.css';
import ReactMarkdown, { Components } from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import { SIZES } from 'shared/constants';
import { getS3AssetsUrl } from 'shared/utils';
import styled from 'styled-components';

export const Markdown = ({ children }: { children: string }) => {
  const { appConfig } = useAppConfig();
  return (
    <ReactMarkdown
      remarkPlugins={[remarkMath, remarkGfm]}
      rehypePlugins={[rehypeKatex, rehypeRaw]}
      components={COMPONENTS(appConfig.s3AssetsUrl)}
    >
      {children}
    </ReactMarkdown>
  );
};

const COMPONENTS = (assetsUrl: string) => {
  return {
    h1: ({ children }) => (
      <Typography
        variant="h6"
        tag="h1"
        fontWeight="700"
        padding={`${SIZES.small}px 0`}
      >
        {children}
      </Typography>
    ),
    h2: ({ children }) => (
      <Typography
        variant="body1"
        tag="h2"
        fontWeight="700"
        padding={`${SIZES.small}px 0`}
      >
        {children}
      </Typography>
    ),
    p: ({ children, node }) => {
      if (node!.children.some(({ type }) => type === 'text')) {
        return (
          <Typography variant="body1" tag="p" padding={`${SIZES.small}px 0`}>
            {children}
          </Typography>
        );
      }
      return <>{children}</>;
    },
    li: ({ children }) => (
      <Typography variant="body1" tag="li" padding={`${SIZES.xSmall}px 0`}>
        {children}
      </Typography>
    ),
    i: ({ children }) => (
      <Typography
        variant="body2"
        tag="i"
        color="black50"
        textTransform="uppercase"
      >
        {children}
      </Typography>
    ),
    figure: ({ children }) => (
      <Typography
        variant="body2"
        tag="figure"
        fontStyle="italic"
        textAlign="center"
        padding={`${SIZES.xSmall}px 0`}
      >
        {children}
      </Typography>
    ),
    img: ({ node }) =>
      node!.properties?.src ? (
        <Img
          {...node!.properties}
          src={getS3AssetsUrl(assetsUrl, node!.properties.src as string)}
        />
      ) : null,
  } satisfies Components;
};

const Img = styled.img`
  max-width: 100%;
  object-fit: cover;
  padding-top: ${SIZES.small}px;
  padding-bottom: ${SIZES.small}px;
`;
