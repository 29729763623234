import { AppConfigProvider } from 'contexts/AppConfigProvider';
import { FC } from 'react';
import { initializeAnalyticsService } from 'shared/analytics/AnalyticsService';
import { GoogleAnalyticsGA4 } from 'shared/analytics/GoogleAnalyticsGA4';
import { createAppConfig } from 'shared/config/create-app-config';
import Routes from './Routes';

declare global {
  interface Window {
    NEATLEAF_APP_CONFIG: Record<string, string>;
  }
}

const appConfig = createAppConfig(window.NEATLEAF_APP_CONFIG);
new GoogleAnalyticsGA4(appConfig.ga4TrackingId);
initializeAnalyticsService(
  appConfig.mixpanel,
  appConfig.environment,
  appConfig.release
);

const App: FC = () => {
  return (
    <div className="App">
      <AppConfigProvider appConfig={appConfig}>
        <Routes />
      </AppConfigProvider>
    </div>
  );
};

export default App;
