import { Label } from 'common';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { AlertMessage, AlertType } from './AlertMessage';

export interface TextAreaInputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLTextAreaElement>,
    'onChange' | 'value' | 'style' | 'defaultValue'
  > {
  label?: string;
  value: string;
  error?: string;
  className?: string;
  onChange: (
    value: string,
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
}

/**
 * TextInput presentation component
 * Currently it's using mui TextField under the hood.
 */
export const TextAreaInput: React.FC<TextAreaInputProps> = forwardRef<
  HTMLTextAreaElement,
  TextAreaInputProps
>(({ label, value, onChange, error, ...rest }, ref) => {
  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    onChange(event.target.value, event);
  };

  return (
    <InputContainer>
      <InputWrapper>
        {label && <Label>{label}</Label>}
        <TextArea ref={ref} value={value} onChange={handleChange} {...rest} />
      </InputWrapper>
      {error ? (
        <AlertMessage type={AlertType.ERROR}>{error}</AlertMessage>
      ) : null}
    </InputContainer>
  );
});

TextAreaInput.displayName = 'TextInput';

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TextArea = styled.textarea({
  padding: '12px',
  height: '100px',
  borderRadius: '3px',
});
