import { createContext, PropsWithChildren, useContext } from 'react';
import { AppConfig } from '../shared/config/AppConfig';

export interface AppConfigProviderProps {
  appConfig: AppConfig;
}

const AppConfigContext = createContext<AppConfigProviderProps>(null!);

export const AppConfigProvider: React.FC<
  PropsWithChildren<AppConfigProviderProps>
> = ({ children, appConfig }) => {
  return (
    <AppConfigContext.Provider value={{ appConfig }}>
      {children}
    </AppConfigContext.Provider>
  );
};

export const useAppConfig = () => useContext(AppConfigContext);
