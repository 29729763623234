import mixpanel, { Mixpanel } from 'mixpanel-browser';
import {
  Environment,
  MixpanelConfig,
  ReleaseConfig,
} from 'shared/config/AppConfig';
import { AnalyticsEvent } from './AnalyticsEvent';

let instance: AnalyticsService | undefined;
export function getAnalyticsServiceInstance(): AnalyticsService {
  if (!instance) {
    throw new Error(`AnalyticsService has not been initialized yet.`);
  }
  return instance;
}

export function setAnalyticsService(service: AnalyticsService): void {
  instance = service;
}

export function initializeAnalyticsService(
  mixpanelConfig: MixpanelConfig,
  environment: Environment,
  releaseConfig: ReleaseConfig
): void {
  if (instance) {
    return;
  }

  instance = new AnalyticsService(mixpanelConfig, environment, releaseConfig);
}

export class AnalyticsService {
  isFirstPageLoad = true;
  skipSendingEvents = false;
  currentUserAgent: string | undefined = undefined;
  currentPage: string | undefined = undefined;
  mixpanelInstance: Mixpanel | undefined = undefined;

  constructor(
    private mixpanelConfig: MixpanelConfig,
    private environment: Environment,
    private releaseConfig: ReleaseConfig
  ) {
    this.currentUserAgent = window.navigator?.userAgent;

    if (mixpanelConfig.enabled) {
      // see https://docs.mixpanel.com/docs/quickstart/connect-your-data?sdk=javascript
      mixpanel.init(mixpanelConfig.apiToken, {
        debug: mixpanelConfig.debuggingEnabled,
        track_pageview: false,
        persistence: 'localStorage',
        loaded: (instance) => {
          this.mixpanelInstance = instance;
        },
      });
    }
  }

  public sendEvent(
    eventType: AnalyticsEvent,
    params?: Record<string, string | number | boolean>
  ): void {
    const augmentedParams = this.getAugmentedParams(params);

    this.wrapAnalyticsFn((mixpanelInstance) => {
      // see https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#sending-events
      mixpanelInstance.track(eventType, augmentedParams);
    });
  }

  public sendPageView(name: string | undefined): void {
    this.currentPage = name;

    const augmentedParams = this.getAugmentedParams({});

    this.wrapAnalyticsFn((mixpanelInstance) => {
      // see https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#tracking-page-views
      mixpanelInstance.track_pageview(augmentedParams);
    });
  }

  public sendPageLeave(path: string): void {
    this.sendEvent(AnalyticsEvent.PageLeave, { path });
  }

  private getAugmentedParams(
    params: Record<
      string,
      string | number | boolean | string[] | undefined
    > = {}
  ): Record<string, string | number | boolean | string[] | undefined> {
    let augmentedParams: Record<
      string,
      string | number | boolean | string[] | undefined
    > = {
      environment: this.environment,
      git_commit: this.releaseConfig.commitSha,
      git_branch: this.releaseConfig.branchName,
      ...params,
    };

    if (this.currentUserAgent) {
      augmentedParams.current_user_agent = this.currentUserAgent;
    }

    if (this.releaseConfig.tagName) {
      augmentedParams.git_tag = this.releaseConfig.tagName;
    }

    if (this.currentPage) {
      augmentedParams.current_page = this.currentPage;
    }

    return augmentedParams;
  }

  private wrapAnalyticsFn(fn: (mixpanelInstance: Mixpanel) => void): void {
    try {
      if (this.mixpanelConfig.enabled && !this.skipSendingEvents) {
        if (!this.mixpanelInstance) {
          console.warn(`Mixpanel was enabled, but no instance was created.`, {
            extra: {
              config: this.mixpanelConfig,
            },
          });
        } else {
          fn(this.mixpanelInstance);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
}
