import { FC } from 'react';
import styled from 'styled-components';
import { Header, HeaderColor } from 'components/Header';
import { Footer } from 'components/Footer/Footer';
import { IResponsiveLayoutProps } from 'shared/types';
import { useScreenSize } from 'hooks/useScreenSize';
import Privacy from 'components/Privacy/Privacy';
import { useScrollToTop } from 'hooks';

const AboutUs: FC = () => {
  const { isMobile } = useScreenSize();
  useScrollToTop();
  return (
    <Container>
      <Header color={HeaderColor.BLACK} />
      <PrivacyWrapper $isMobile={isMobile}>
        <Privacy />
      </PrivacyWrapper>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const PrivacyWrapper = styled.div<IResponsiveLayoutProps>`
  padding: ${({ $isMobile: isMobile }) =>
    isMobile ? '40px 0px 60px' : '110px 0px 100px'};
  display: flex;
  justify-content: center;
`;

export default AboutUs;
