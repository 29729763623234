import React, { FC } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import styled from 'styled-components';

import { COLORS } from 'shared/constants';

interface LinkProps extends Omit<RouterLinkProps, 'color'> {
  /**
   * Text to be rendered inside the component.
   */
  text: string;
  /**
   * Color to be applied to the link.
   */
  color?: keyof typeof COLORS;
  /**
   * Class to be added to the link.
   */
  className?: string;
  /**
   * Color to be applied to the link.
   */
  hoverColor?: keyof typeof COLORS;
  /**
   * Variant to be applied to the link.
   */
  variant?: 'text' | 'contained';
}

export const Link: FC<LinkProps> = ({
  color = 'white',
  className,
  hoverColor = 'grey5',
  text,
  to,
  variant = 'text',
  ...props
}) => {
  return (
    <StyledLink
      className={className}
      $linkColor={color}
      $hoverColor={hoverColor}
      to={to}
      $variant={variant}
      {...props}
    >
      {text}
    </StyledLink>
  );
};

interface StyledLinkProps {
  $linkColor: keyof typeof COLORS;
  $hoverColor: keyof typeof COLORS;
  $variant: 'text' | 'contained';
}

const StyledLink = styled(RouterLink)<StyledLinkProps>`
  ${({ $variant, $hoverColor, $linkColor }) => {
    switch ($variant) {
      case 'text':
        return `
          font-family: Poppins;
          font-weight: 700;
          font-size: 14px;
          text-decoration: none;
          color: ${COLORS[$linkColor]};
          line-height: 1;
          transition: color 0.2s ease-in-out;
        
          &:hover {
            color: ${COLORS[$hoverColor]};
          }
        `;
      case 'contained':
      default:
        return `
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px 18px;

          background: ${COLORS.orange3};
          color: ${$linkColor};

          &:hover {
            background: ${COLORS.orange4};
            color: ${$hoverColor};
          }

          transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

          border-radius: 4rem;
          cursor: pointer;

          font-family: Poppins;
          font-weight: 700;
          font-size: 14px;
          line-height: 1;
          text-decoration: none;
        `;
    }
  }}
`;
