import styled, { CSSProperties } from 'styled-components';

import { useAppConfig } from 'contexts/AppConfigProvider';
import { basename, extname } from 'path-browserify';
import {
  EXTENDED_RESOLUTIONS_RANGES,
  RESOLUTION_RANGES,
  TYPES,
} from 'shared/constants';
import { getS3AssetsUrl } from 'shared/utils';

/**
 * This component will work progressively.
 * If the images are not found in the public/media folder
 * it will always fallback to the provided fallback
 * so make sure to provide an existing fallback image
 *
 * Usage example:
 * import MyImg from "somewhere/images/my-image.jpg"
 *
 * <ResponsiveImg filename="my-image" extension="jpg" fallback={MyImg} />
 *
 * @param {Object} props
 * @returns HTMLPictureElement
 */

export interface IResponsiveImg {
  filename: string;
  objectPosition?: CSSProperties['objectPosition'];
  maxHeight?: CSSProperties['maxHeight'];
  useExtendedResolution?: boolean;
}

const ResponsiveImg = ({
  filename,
  objectPosition,
  maxHeight,
  useExtendedResolution = false,
}: IResponsiveImg) => {
  const { appConfig } = useAppConfig();
  // NOTE: we're interested in generating a list
  // of all possible resolutions for webp and
  // an original source file extension
  const extension = extname(filename);
  const baseFilename = basename(filename, extension);
  let resolutionRanges = RESOLUTION_RANGES;
  if (useExtendedResolution) resolutionRanges = EXTENDED_RESOLUTIONS_RANGES;
  const variants = TYPES.concat(extension)
    .map((type) =>
      resolutionRanges.map(({ resolution, media }) => ({
        type: `image/${type}`,
        media,
        srcSet: getS3AssetsUrl(
          appConfig.s3AssetsUrl,
          `/${baseFilename}_${resolution}px.${type}`
        ),
      }))
    )
    .flat();

  const fallback = getS3AssetsUrl(appConfig.s3AssetsUrl, filename);
  return (
    <Picture>
      {variants.map((variant) => (
        <source key={variant.srcSet} {...variant} />
      ))}
      <Img
        src={fallback}
        $objectPosition={objectPosition}
        $maxHeight={maxHeight}
      />
    </Picture>
  );
};

const Picture = styled.picture`
  max-height: 100%;
`;

const Img = styled.img<{
  $objectPosition?: CSSProperties['objectPosition'];
  $maxHeight?: CSSProperties['maxHeight'];
}>`
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${(props) =>
    props.$objectPosition ? `object-position: ${props.$objectPosition}` : ''}
  ${(props) => (props.$maxHeight ? `max-height: ${props.$maxHeight}` : '')}
`;

export default ResponsiveImg;
