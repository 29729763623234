import { useCallback } from 'react';
import { AnalyticsEvent } from './AnalyticsEvent';
import { getAnalyticsServiceInstance } from './AnalyticsService';

export const useAnalytics = () => {
  const analyticsService = getAnalyticsServiceInstance();

  const sendEvent = useCallback(
    (
      eventType: AnalyticsEvent,
      params?: Record<string, string | number | boolean>
    ) => {
      analyticsService.sendEvent(eventType, params);
    },
    [analyticsService]
  );

  const sendPageLeave = useCallback(
    (path: string) => {
      analyticsService.sendPageLeave(path);
    },
    [analyticsService]
  );

  const sendPageView = useCallback(
    (name: string | undefined) => {
      analyticsService.sendPageView(name);
    },
    [analyticsService]
  );

  return {
    sendEvent,
    sendPageLeave,
    sendPageView,
  };
};
