import RightArrowIcon from 'assets/svgs/right-arrow.svg?react';
import { Typography } from 'common';
import { useAppConfig } from 'contexts/AppConfigProvider';
import { useFetch } from 'hooks';
import { FC } from 'react';
import { COLORS, FONTS_MOBILE, SCREEN_SIZES, SIZES } from 'shared/constants';
import { getS3AssetsUrl } from 'shared/utils';
import styled from 'styled-components';

interface NewsItem {
  meta: string;
  headline: string;
  url: string;
  imageUrl?: string;
}

export const Newsroom: FC = () => {
  const { appConfig } = useAppConfig();
  const { data: index = [], loading } = useFetch<NewsItem[]>(
    getS3AssetsUrl(appConfig.s3AssetsUrl, 'newsroom/index.json')
  );

  if (index.length === 0 && !loading) {
    return (
      <Typography variant="body1" tag="p">
        Coming soon!
      </Typography>
    );
  }

  return (
    <List>
      {index.map(({ meta, headline, imageUrl, url }) => {
        return (
          <Link key={url} href={url} target="_blank">
            <Section>
              {imageUrl && (
                <Img src={getS3AssetsUrl(appConfig.s3AssetsUrl, imageUrl)} />
              )}

              <Meta variant="body2">{meta}</Meta>

              <Headline variant="body3" tag="h2">
                <span>{headline}</span>
              </Headline>

              <SectionLink>
                Read more
                <RightArrowIcon />
              </SectionLink>
            </Section>
          </Link>
        );
      })}
    </List>
  );
};

const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${SIZES.medium}px;
`;

const Link = styled.a`
  flex-grow: 2;
  max-width: 100%;
  background-color: ${COLORS.white1};
  border-radius: ${SIZES.medium}px;
  padding: ${SIZES.large}px;

  text-decoration: none;
  color: ${COLORS.black};
  &:visited {
    color: ${COLORS.black};
  }

  @media (width > ${SCREEN_SIZES.xs}px) {
    max-width: 216px;
  }

  @media (width > ${SCREEN_SIZES.sm}px) {
    max-width: 276px;
  }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${SIZES.medium}px;
  height: 100%;
  align-items: flex-start;
`;

const Meta = styled(Typography)`
  color: ${COLORS.black50};
  text-transform: uppercase;
  font-size: ${FONTS_MOBILE.body3.size}px;
  line-height: normal;
`;

const Headline = styled(Typography)`
  font-weight: 400;
  line-height: normal;
`;

const SectionLink = styled.div`
  color: ${COLORS.orange3};
  font-family: Poppins;
  font-size: ${FONTS_MOBILE.body3.size}px;
  line-height: ${FONTS_MOBILE.body3.lineHeight}rem;
  font-weight: 600;
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: ${SIZES.xSmall}px;
`;

const Img = styled.img`
  height: 57px;
  object-fit: contain;
  max-width: 100%;
`;
