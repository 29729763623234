import { ButtonSliders } from 'components/ButtonSliders';
import ResponsiveImg from 'components/ResponsiveImg';
import { useAppConfig } from 'contexts/AppConfigProvider';
import { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { TSliderItem } from 'shared/types';
import { preloadImageSources } from 'shared/utils';
import { PanelGroup } from './PanelGroup';

const sensorsIntegration = 'sensors-integration.jpg';
const sensorsSubstrate = 'sensors-substrate.jpg';

const imageSources: string[] = [sensorsIntegration, sensorsSubstrate];

const sliders: TSliderItem[] = [
  {
    button: 'Sensor integration',
    description: (
      <>
        It&apos;s our core motivation to support cultivators with data on all
        variables that influence plant growth and provide crucial information to
        detect issues before they happen. We&apos;ll continue to integrate more
        sensors with the Neatleaf platform.
        <br />
        <br />
        Please get in touch, if you think we are missing vital sensors that
        would support you as a cultivator.
      </>
    ),
  },
  {
    button: 'Substrate',
    description:
      'By partnering with industry-leading substrate sensors, Neatleaf ensures your plants reach optimal water and nutrient levels. Combined with the Neatleaf Spyder, you can monitor everything from roots to canopy — a live, digital version of your entire crop, accessible from anywhere.',
  },
];

export const SubstrateSensors: FC = () => {
  const { appConfig } = useAppConfig();
  const [sliderIndex, setSliderIndex] = useState(0);
  const imageSrc = imageSources[sliderIndex];

  const preload = preloadImageSources(appConfig.s3AssetsUrl, imageSources);

  return (
    <>
      <Helmet>
        {preload.map((props) => (
          <link key={props.href} {...props} />
        ))}
      </Helmet>
      <PanelGroup
        panels={[
          <ResponsiveImg key="left" filename={imageSrc} />,
          <ButtonSliders
            key="right"
            current={sliderIndex}
            items={sliders}
            title="Sensors from top to bottom"
            onChange={setSliderIndex}
          />,
        ]}
      />
    </>
  );
};
