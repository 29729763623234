import { ButtonSliders } from 'components/ButtonSliders';
import ResponsiveImg from 'components/ResponsiveImg';
import { useAppConfig } from 'contexts/AppConfigProvider';
import { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { TSliderItem } from 'shared/types';
import { preloadImageSources } from 'shared/utils';
import { PanelGroup } from './PanelGroup';

const spyderInstallation = 'spyder-installation.jpg';
const spyderConsistency = 'spyder-consistency.jpg';
const spyderTransparent = 'spyder-transparent.png';
const spyderInterface = 'spyder-interface.jpg';

const imageSources: string[] = [
  spyderConsistency,
  spyderTransparent,
  spyderInstallation,
  spyderInterface,
];

const sliders: TSliderItem[] = [
  {
    button: 'Consistency',
    description:
      'The Spyder contains several sensors to gather a comprehensive understanding of the cultivation environment. The same sensor set is used to collect the data across the canopy which guarantees consistency and ensures interpretability.',
  },
  {
    button: 'Transparent',
    description:
      'The Spyder operates above the canopy - out of the way of your workforce. It is fully autonomous and does not require any human intervention.',
  },
  {
    button: 'Installation',
    description:
      'Our cable based robot is highly flexible and can be installed within a couple of hours in most controlled environment cultivations.',
  },
  {
    button: 'Interface',
    description:
      'Only three simple commands are required to interact with the Spyder: start measuring, stop where you are, and move to Home location.',
  },
];

export const NeatleafSpyder: FC = () => {
  const { appConfig } = useAppConfig();
  const [sliderIndex, setSliderIndex] = useState(0);
  const imageSrc = imageSources[sliderIndex];

  const preload = preloadImageSources(appConfig.s3AssetsUrl, imageSources);

  return (
    <>
      <Helmet>
        {preload.map((props) => (
          <link key={props.href} {...props} />
        ))}
      </Helmet>
      <PanelGroup
        panels={[
          <ResponsiveImg key="left" filename={imageSrc} />,
          <ButtonSliders
            key="right"
            current={sliderIndex}
            items={sliders}
            title="The Neatleaf Spyder"
            onChange={setSliderIndex}
          />,
        ]}
      />
    </>
  );
};
