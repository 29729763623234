import { default as ReactGA4 } from 'react-ga4';
import { TMetricsEvent } from 'shared/analytics/contracts';

/**
 * Google Analytics Engine for GA4
 *
 * @export
 * @class GoogleAnalyticsGA4
 * @implements {IAnalytics}
 */
export class GoogleAnalyticsGA4 {
  static isInitialized: boolean;

  /**
   * Creates an instance of GoogleAnalyticsGA4.
   * @param {string | undefined} trackingId tracking ID
   * @memberof GoogleAnalyticsGA4
   */
  constructor(trackingId: string | undefined) {
    GoogleAnalyticsGA4.isInitialized =
      GoogleAnalyticsGA4.initialize(trackingId);
  }

  /**
   * Initializes engine with tracking ID.
   *
   * @param {string | undefined} trackingId Tracking ID
   * @returns {boolean} Whether initialization was successful.
   * @memberof GoogleAnalyticsGA4
   */
  static initialize(trackingId: string | undefined): boolean {
    if (!trackingId) {
      console.warn('GA4 trackingId is missing: tracking will be disabled');
      return false;
    }
    ReactGA4.initialize(trackingId);
    console.info('GA4 tracking initialized');
    return true;
  }

  /**
   * Send page view event
   *
   * @param {string} pathname URL of page view
   * @memberof GoogleAnalyticsGA4
   */
  static sendPageView(pathname: string): void {
    if (!GoogleAnalyticsGA4.isInitialized) {
      return;
    }
    ReactGA4.send({ hitType: 'pageview', page: pathname });
  }

  /**
   * Send event to be recorded by google analytics
   *
   * @param {TMetricsEvent} event Event to be sent
   * @memberof GoogleAnalyticsGA4
   */
  static sendEvent(event: TMetricsEvent): void {
    if (!GoogleAnalyticsGA4.isInitialized) {
      return;
    }
    ReactGA4.event(event);
  }
}
