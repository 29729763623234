import React, { forwardRef } from 'react';
import { COLORS } from 'shared/constants';
import styled from 'styled-components';

import { AlertMessage, AlertType } from './AlertMessage';
export interface TextInputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'value' | 'style' | 'defaultValue'
  > {
  label?: string;
  value: string;
  error?: string;
  className?: string;
  onChange: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * TextInput presentation component
 * Currently it's using mui TextField under the hood.
 */
export const TextInput: React.FC<TextInputProps> = forwardRef<
  HTMLInputElement,
  TextInputProps
>(({ label, value, onChange, error, ...rest }, ref) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange(event.target.value, event);
  };

  return (
    <InputContainer>
      <InputWrapper>
        {label && <Label>{label}</Label>}
        <Input ref={ref} value={value} onChange={handleChange} {...rest} />
      </InputWrapper>
      {error ? (
        <AlertMessage type={AlertType.ERROR}>{error}</AlertMessage>
      ) : null}
    </InputContainer>
  );
});

TextInput.displayName = 'TextInput';

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.label`
  margin-bottom: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.5;
  color: ${COLORS.black};
`;

const Input = styled.input({
  padding: '12px',
  borderRadius: '3px',
  borderWidth: '1px',
});
