import { Typography } from 'common';
import Layout, { Content, Section } from 'common/Layout';
import Form from 'components/ContactUs/Form';
import { FC } from 'react';
import { FormType } from 'shared/constants';

const Promotion: FC = () => {
  return (
    <Layout>
      <Content>
        <Section>
          <Typography variant="h4" tag="h1" margin="0 0 40px 0">
            Contact us to register for the Summer Spyder Program
          </Typography>
          <Typography variant="h6">
            We look forward to hearing from you! Please reach out and a member
            of our team will be in touch to discuss how our system can support
            your cultivation goals.
          </Typography>
        </Section>
        <Section>
          <Form formType={FormType.PROMOTION} />
        </Section>
      </Content>
    </Layout>
  );
};

export default Promotion;
