import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from './useAnalytics';

export const useTrackPageViews = () => {
  const { sendPageView, sendPageLeave } = useAnalytics();
  const location = useLocation();

  useEffect(() => {
    sendPageView(location.pathname);

    return () => {
      {
        sendPageLeave(location.pathname);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
};
