export enum Environment {
  DEV = 'dev',
  PROD = 'prod',
}

export interface AppConfig {
  environment: Environment;
  ga4TrackingId?: string;
  release: ReleaseConfig;
  mailSubmitApi: string;
  mixpanel: MixpanelConfig;
  s3AssetsUrl: string;
}

export interface ReleaseConfig {
  branchName: string | undefined;
  commitSha: string;
  commitShortSha: string;
  releaseName: string;
  tagName: string | undefined;
  tagVersion: string | undefined;
}

export interface MixpanelConfig {
  enabled: boolean;
  debuggingEnabled: boolean;
  apiToken: string;
}
