import { Hr, Typography } from 'common';
import { useAppConfig } from 'contexts/AppConfigProvider';
import { useFetch } from 'hooks';
import { FC, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  COLORS,
  DEVICES,
  FONTS,
  ROUTES,
  SCREEN_SIZES,
  SIZES,
} from 'shared/constants';
import { getS3AssetsUrl } from 'shared/utils';
import styled, { css } from 'styled-components';

interface PostIndexItem {
  id: string;
  meta: string;
  headline: string;
  summary: string;
  featuredImage: string;
  authorId: string;
}

const getPostPath = (id: string, authorId: string) =>
  `${ROUTES.BLOG}/${id}?authorId=${authorId}`;

interface PostsProps {
  complete?: boolean;
}

export const Posts: FC<PostsProps> = ({ complete = true }) => {
  const { appConfig } = useAppConfig();
  const { data: index = [], loading } = useFetch<PostIndexItem[]>(
    getS3AssetsUrl(appConfig.s3AssetsUrl, 'blog/index.json')
  );

  const navigate = useNavigate();
  const handleOnNavigate = (id: string, authorId: string) => () => {
    navigate(getPostPath(id, authorId));
  };

  if (index.length === 0 && !loading) {
    return (
      <Typography variant="body1" tag="p">
        Coming soon!
      </Typography>
    );
  }

  return (
    <List>
      {index.map(
        ({ id, meta, headline, summary, featuredImage, authorId }, i) => {
          return (
            <Fragment key={id}>
              {i !== 0 && <Hr />}

              <Article
                key={id}
                $complete={complete}
                {...(!complete
                  ? { onClick: handleOnNavigate(id, authorId) }
                  : {})}
              >
                <Section>
                  <MetaAndHeadline>
                    <Meta
                      variant="body2"
                      tag="i"
                      $complete={complete}
                      queryType="container"
                    >
                      {meta}
                    </Meta>

                    <Headline variant="body1" tag="h2" queryType="container">
                      <span onClick={handleOnNavigate(id, authorId)}>
                        {headline}
                      </span>
                    </Headline>
                  </MetaAndHeadline>

                  <Summary
                    variant="body1"
                    tag="p"
                    queryType="container"
                    $complete={complete}
                  >
                    {summary}
                  </Summary>
                  <SectionLink
                    href={getPostPath(id, authorId)}
                    $complete={complete}
                  >
                    Read post
                  </SectionLink>
                </Section>
                <Img
                  src={getS3AssetsUrl(appConfig.s3AssetsUrl, featuredImage)}
                  $complete={complete}
                  onClick={handleOnNavigate(id, authorId)}
                />
              </Article>
            </Fragment>
          );
        }
      )}
    </List>
  );
};

interface ResponsivePosts {
  $complete?: boolean;
}

const List = styled.div`
  align-self: flex-start;
  container-type: inline-size;
  display: flex;
  flex-direction: column;
  gap: ${SIZES.xLarge}px;
`;

const Article = styled.article<ResponsivePosts>`
  display: flex;
  flex-direction: row;
  gap: ${SIZES.small}px;
  justify-content: space-between;

  ${({ $complete }) =>
    $complete
      ? css`
          @media (width < ${SCREEN_SIZES.xs}px) {
            flex-direction: column-reverse;
          }
        `
      : css`
          cursor: pointer;

          @container (width < ${SCREEN_SIZES.xs}px) {
            flex-direction: row;
          }

          @media (width > ${SCREEN_SIZES.md}px) {
            flex-direction: column-reverse;
          }
        `}
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${SIZES.xSmall}px;
`;

const MetaAndHeadline = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZES.xSmall}px;
`;

const Meta = styled(Typography)<ResponsivePosts>`
  display: ${({ $complete }) => ($complete ? 'block' : 'none')};
  color: ${COLORS.black50};
  text-transform: uppercase;

  @media ${DEVICES.sm} {
    display: block;
  }
`;

const Headline = styled(Typography)`
  font-weight: 700;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Summary = styled(Typography)<ResponsivePosts>`
  display: ${({ $complete }) => ($complete ? '-webkit-box' : 'none')};
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  @media ${DEVICES.sm} {
    display: -webkit-box;
  }
`;

const SectionLink = styled.a<ResponsivePosts>`
  display: ${({ $complete }) => ($complete ? 'block' : 'none')};
  color: ${COLORS.black};
  font-family: Poppins;
  font-size: ${FONTS.body2.size}px;
  font-weight: 500;
  text-decoration: underline;

  @media ${DEVICES.sm} {
    display: block;
  }
`;

const Img = styled.img<ResponsivePosts>`
  height: 280px;
  min-width: 400px;
  object-fit: cover;
  border-radius: ${SIZES.small}px;

  &:hover {
    cursor: pointer;
  }

  ${({ $complete }) =>
    $complete
      ? css`
          @media (max-width: ${SCREEN_SIZES.md}px) {
            height: 180px;
            min-width: 250px;
          }
        `
      : css`
          @container (width < ${SCREEN_SIZES.xs}px) {
            height: 180px;
            min-width: 100%;
          }

          @media (width < ${SCREEN_SIZES.md}px) {
            height: 180px;
            min-width: 250px;
          }

          @media (width < ${SCREEN_SIZES.sm}px) {
            height: 80px;
            width: 80px;
            min-width: 80px;
          }
        `}
`;
