import { CSSProperties, ReactNode, createElement } from 'react';
import styled, { css } from 'styled-components';
import {
  COLORS,
  DEVICES,
  FONTS,
  FONTS_MOBILE,
  SCREEN_SIZES,
} from 'shared/constants';

type TagVariants =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'span'
  | 'li'
  | 'i'
  | 'figure';

interface TypographyProps extends DynamicTypographyProps {
  children: ReactNode;
  variant?: keyof typeof FONTS;
  queryType?: 'media' | 'container';
  className?: string;
  tag?: TagVariants;
}

interface DynamicTypographyProps {
  color?: keyof typeof COLORS;
  $variant?: keyof typeof FONTS;
  $queryType?: 'media' | 'container';
  fontWeight?: string;
  lineHeight?: number | string;
}

const DynamicTypography = styled(
  // NOTE: Filtering these properties out since they have their own style logic
  // This prevents having `lineheight='value'` on the HTML Element in the DOM
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ tag, children, fontWeight, color, lineHeight, margin, ...props }) =>
    createElement(tag, props, children)
)`
  color: ${(props) => COLORS[props.color as keyof typeof COLORS]};
  font-family: Poppins;
  line-height: ${(props) =>
    props.lineHeight ||
    FONTS_MOBILE[props.$variant as keyof typeof FONTS_MOBILE].lineHeight ||
    1.35};
  margin: ${(props) => props.margin || 0};

  font-size: ${(props) =>
    FONTS_MOBILE[props.$variant as keyof typeof FONTS_MOBILE].size}px;
  font-weight: ${(props) =>
    props.fontWeight ||
    FONTS_MOBILE[props.$variant as keyof typeof FONTS_MOBILE].weight};
  font-style: ${(props) =>
    FONTS_MOBILE[props.$variant as keyof typeof FONTS_MOBILE].style};

  ${(props) =>
    props.$queryType === 'media'
      ? css`
          @media ${DEVICES.sm} {
            font-size: ${FONTS[props.$variant as keyof typeof FONTS].size}px;
            font-weight: ${props.fontWeight ||
            FONTS[props.$variant as keyof typeof FONTS].weight};
            font-style: ${FONTS[props.$variant as keyof typeof FONTS].style};
            line-height: ${props.lineHeight ||
            FONTS[props.$variant as keyof typeof FONTS].lineHeight ||
            1.35};
          }
        `
      : css`
          @container (width > ${SCREEN_SIZES.md}px) {
            font-size: ${FONTS[props.$variant as keyof typeof FONTS].size}px;
            font-weight: ${props.fontWeight ||
            FONTS[props.$variant as keyof typeof FONTS].weight};
            font-style: ${FONTS[props.$variant as keyof typeof FONTS].style};
            line-height: ${props.lineHeight ||
            FONTS[props.$variant as keyof typeof FONTS].lineHeight ||
            1.35};
          }
        `}
`;

export const Typography = ({
  tag = 'p',
  children,
  className,
  variant = 'h5',
  queryType = 'media',
  fontWeight,
  color,
  lineHeight,
  margin,
  ...props
}: TypographyProps & CSSProperties) => (
  <DynamicTypography
    tag={tag}
    className={className}
    $variant={variant}
    $queryType={queryType}
    fontWeight={fontWeight}
    color={color}
    lineHeight={lineHeight}
    margin={margin}
    style={{ ...props }}
  >
    {children}
  </DynamicTypography>
);
