import { FC } from 'react';
import styled from 'styled-components';

import { Typography } from 'common';
import Layout, { Content } from 'common/Layout';
import { Newsroom as NR } from 'components/Newsroom/Newsroom';
import { COLORS, DEVICES } from 'shared/constants';

export const Newsroom: FC = () => {
  return (
    <Layout backgroundColor={COLORS.white}>
      <NewsroomContent>
        <Title variant="h4" tag="h1">
          Neatleaf in the news
        </Title>
        <NR />
      </NewsroomContent>
    </Layout>
  );
};

const NewsroomContent = styled(Content)`
  grid-template-columns: 1fr;
  align-content: start;
  grid-row-gap: 24px;

  @media ${DEVICES.sm} {
    grid-row-gap: 48px;
  }
`;

const Title = styled(Typography)`
  line-height: normal;
`;
