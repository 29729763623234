import { RouteChangeTracker } from 'common/RouteChangeTracker';
import { AboutUs } from 'containers/AboutUs/AboutUs';
import { Blog } from 'containers/Blog/Blog';
import { Post } from 'containers/Blog/Post';
import { Careers } from 'containers/Careers/Careers';
import ContactUs from 'containers/ContactUs/ContactUs';
import { GetNeatleafPage } from 'containers/GetNeatleafPage';
import { Landing } from 'containers/Landing/Landing';
import Maintenance from 'containers/Maintenance/Maintenance';
import { Newsroom } from 'containers/Newsroom/Newsroom';
import Privacy from 'containers/Privacy/Privacy';
import Promotion from 'containers/Promotion/Promotion';
import { ScheduleDemoPage } from 'containers/ScheduleDemo/ScheduleDemoPage';
import { FC } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ROUTES } from 'shared/constants';

const LandingPageRoutes: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.LANDING} element={<Landing />} />
        <Route path={ROUTES.ABOUT_US} element={<AboutUs />} />
        <Route path={ROUTES.CAREERS} element={<Careers />} />
        <Route path={ROUTES.CONTACT_US} element={<ContactUs />} />
        <Route path={ROUTES.PROMOTION} element={<Promotion />} />
        <Route path={ROUTES.SCHEDULE_DEMO} element={<ScheduleDemoPage />} />
        <Route path={ROUTES.PRIVACY} element={<Privacy />} />
        <Route path={ROUTES.MAINTENANCE} element={<Maintenance />} />
        <Route path={ROUTES.BLOG} element={<Blog />} />
        <Route path={`${ROUTES.BLOG}/:postId`} element={<Post />} />
        <Route path={ROUTES.NEWSROOM} element={<Newsroom />} />
        <Route path={ROUTES.GET_NEATLEAF} element={<GetNeatleafPage />} />
        <Route element={<Navigate to={ROUTES.LANDING} replace />} />
      </Routes>
      <RouteChangeTracker />
    </BrowserRouter>
  );
};

export default LandingPageRoutes;
