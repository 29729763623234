import ArrowLeftIcon from 'assets/svgs/arrow-left.svg?react';
import ArrowRightIcon from 'assets/svgs/arrow-right.svg?react';
import { Typography } from 'common';
import { useAppConfig } from 'contexts/AppConfigProvider';
import DOMPurify from 'dompurify';
import { useFetch } from 'hooks';
import { FC } from 'react';
import Slider from 'react-slick';
import { DEVICES } from 'shared/constants';
import { getS3AssetsUrl } from 'shared/utils';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';

// https://react-slick.neostack.com/docs/api
const SLICK_SETTINGS = {
  autoplay: true,
  autoplaySpeed: 10000,
  dots: true,
  prevArrow: (
    <button>
      <ArrowLeftIcon width="24px" />
    </button>
  ),
  nextArrow: (
    <button>
      <ArrowRightIcon width="24px" />
    </button>
  ),
};

interface Testimonial {
  text: string;
  name: string;
  role: string;
  imageUrl?: string;
}

export const Testimonials: FC = () => {
  const { appConfig } = useAppConfig();
  const { data: testimonials = [] } = useFetch<Testimonial[]>(
    getS3AssetsUrl(appConfig.s3AssetsUrl, 'testimonials.json')
  );
  const hasMultiple = testimonials.length > 1;

  if (testimonials.length === 0) {
    return null;
  }

  return (
    <Section>
      <StyledSlider
        {...SLICK_SETTINGS}
        infinite={hasMultiple}
        swipe={hasMultiple}
        dots={hasMultiple}
      >
        {testimonials.map((testimonial) => (
          <Article key={testimonial.name}>
            {testimonial.imageUrl ? (
              <Img
                src={getS3AssetsUrl(
                  appConfig.s3AssetsUrl,
                  testimonial.imageUrl
                )}
              />
            ) : (
              <ImgPlaceholder />
            )}

            <Typography variant="h6" tag="p" textAlign="center">
              <span
                dangerouslySetInnerHTML={{
                  __html: `”${DOMPurify.sanitize(testimonial.text)}”`,
                }}
              />
            </Typography>

            <Typography
              variant="h6"
              tag="p"
              fontWeight="600"
              textAlign="center"
            >
              {testimonial.name} ~{' '}
              <Typography variant="h6" tag="span" color="dark7">
                {testimonial.role}
              </Typography>
            </Typography>
          </Article>
        ))}
      </StyledSlider>
    </Section>
  );
};

const Section = styled.section`
  min-height: 0;
  min-width: 0;
  padding: 80px 24px;

  @media ${DEVICES.sm} {
    padding: 80px 128px;
  }
`;

const StyledSlider = styled(Slider)`
  .slick-list {
    height: max-content !important;
  }
  .slick-track {
    display: flex;
    align-items: center;
  }
  .slick-arrow {
    display: none !important;

    @media ${DEVICES.sm} {
      position: absolute;
      appearance: none;
      border: none;
      height: 36px;
      min-width: 36px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      background-color: #f2f2f2;
      &:hover {
        background-color: #e5e5e5;
      }
      &::before {
        content: '';
      }
    }
  }
  .slick-prev {
    left: -64px;
  }
  .slick-next {
    right: -64px;
  }
  .slick-disabled {
    display: none !important;
  }
  .slick-dots {
    bottom: unset;
    position: relative;
    padding-top: 32px;
    li {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: unset;
    }
    button {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: #cccccc;
      padding: unset;
      &::before {
        content: '';
      }
    }
    .slick-active button {
      background-color: #808080;
    }
  }
`;

const Article = styled.article`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  gap: 32px !important;
  flex-grow: 2;
`;

const Img = styled.img`
  height: 88px;
  object-fit: contain;
  max-width: 100%;
`;

const ImgPlaceholder = styled.div`
  height: 88px;
`;
