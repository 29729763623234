export enum AnalyticsEvent {
  Click = 'click',
  ContactUsSubmitted = 'contact_us_submitted',
  DashboardLogin = 'dashboard_login',
  PageLeave = 'page_leave',
  Submit = 'submit',
  RequestDemoStarted = 'request_demo_started',
  RequestDemoSubmitted = 'request_demo_submitted',
  SliderNavigation = 'slider_navigation',
  ViewCurrentOpenings = 'view_current_openings',
}
