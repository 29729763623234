import { Link, Typography } from 'common';
import { Footer } from 'components/Footer/Footer';
import { Hero } from 'components/Landing/Hero';
import { NeatleafDashboard } from 'components/Landing/NeatleafDashboard';
import { NeatleafIntelligence } from 'components/Landing/NeatleafIntelligence';
import { NeatleafSpyder } from 'components/Landing/NeatleafSpyder';
import { SubstrateSensors } from 'components/Landing/SubstrateSensors';
import { useScrollToTop } from 'hooks';
import { AnalyticsEvent } from 'shared/analytics/AnalyticsEvent';
import { useAnalytics } from 'shared/analytics/useAnalytics';
import { COLORS, DEVICES, ROUTES } from 'shared/constants';
import styled from 'styled-components';
import { USP } from '../components/ScheduleDemo/USP';

export const GetNeatleafPage = () => {
  const { sendEvent } = useAnalytics();
  useScrollToTop();
  return (
    <Container>
      <Hero />
      <RequestDemoContainer>
        <RequestDemoPanel>
          <Typography
            textAlign="center"
            variant="h4"
            tag="h2"
            margin="0 0 48px 0"
          >
            Grow with confidence and stop the guesswork
          </Typography>
          <RequestDemoLink
            color="white"
            text="Request a demo"
            to={ROUTES.SCHEDULE_DEMO}
            variant="contained"
            onClick={() => sendEvent(AnalyticsEvent.RequestDemoStarted)}
          />
        </RequestDemoPanel>
        <USP />
      </RequestDemoContainer>

      <SectionContainer>
        <NeatleafSpyder />
        <NeatleafDashboard />
        <NeatleafIntelligence />
        <SubstrateSensors />
      </SectionContainer>
      <Footer />
    </Container>
  );
};

const RequestDemoLink = styled(Link)({
  fontSize: '18px',
});

const RequestDemoPanel = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
`;

const RequestDemoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 65px 20px;
  gap: 80px;
`;

const SectionContainer = styled.div`
  > :nth-child(2n) {
    background-color: ${COLORS.white};

    > *:first-child {
      order: initial;

      @media ${DEVICES.sm} {
        order: 1;
      }
    }
  }

  > :nth-child(2n + 1) {
    background-color: ${COLORS.hb7};
  }
`;

const Container = styled.div({
  position: 'relative',
  width: '100%',
});
