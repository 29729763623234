import { ReactNode } from 'react';
import styled from 'styled-components';

import { DEVICES } from 'shared/constants';

export const PanelGroup = ({
  panels,
  className,
}: {
  panels: ReactNode[];
  className?: string;
}) => (
  <Container className={className}>
    {panels.map((panel, index) => (
      <div key={index}>{panel}</div>
    ))}
  </Container>
);

const Container = styled.section`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  padding: 0;
  width: 100%;

  @media ${DEVICES.sm} {
    grid-template-columns: 1fr 1fr;
  }
`;
